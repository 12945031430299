<template>
  <div>
    <ul class="">
      <li v-for="items in list" :key="items.id" class="flex" style="cursor: pointer;" @click="goToDetail(items.id)">
        <img :src="items.activityImg" alt="" />
        <div>
          <div>{{ items.activityName }}</div>
          <div>
            <i class="el-icon-time"></i>
            <span>活动时间：{{ timeIntercept(items.activityStartTime) }}至{{
                timeIntercept(items.activityEndTime)
              }}</span>
          </div>
          <div>
            <i class="el-icon-location-outline"></i>
            <span>活动地点：{{ items.activityAdress }}</span>
          </div>
          <div>
            <i class="el-icon-wallet"></i>
            <span>活动状态：<span style="color: #f93f3f; font-size: 20px">{{
                items.statusText
              }}</span></span>
          </div>
        </div>
        <div v-if="items.statusText == '进行中'" class="application-btn" @click.stop="cancellationApplication(items.id)">
          取消报名
        </div>
        <div v-else class="application-btn" style="opacity: 0.5">取消报名</div>
        <div v-if="items.isRecommend === 1" class="mark">推荐</div>
      </li>
    </ul>
    <el-pagination v-if="activityList.length > 0" background layout="prev, pager, next" :page-size="pageSize" :total="pageListTotal" :current-page="currentPage" @current-change="handleCurrentChange" style="text-align: center; margin-bottom: 30px">
      <!-- @current-change="getTongzhiListData" -->
    </el-pagination>
  </div>
</template>

<script>
import { request } from "@/network";
import { getMemberId } from "@/assets/public/utils/token";
export default {
  components: {},
  props: {
    pageListTotal: {
      type: Number,
    },
    pageSize: {
      type: Number,
    },
    currentPage: {
      type: Number,
    },
    activityList: {
      type: Array,
      default: () => [],
    },
    paginationFlg: {
      type: Boolean,
      default: () => true,
    },
  },
  watch: {
    activityList: {
      handler(newValue, oldValue) {
        this.init(newValue);
      },
      deep: true,
    },
  },
  data() {
    return {
      btnFlg: true,
      list: [],
      // pageListTotal: 0,
      // pageSize: 10,
      // currentPage: 1,
      // activityList: JSON.parse(sessionStorage.getItem("topicList")),
    };
  },
  created() { },
  async mounted() {
    this.init(this.activityList);
  },
  methods: {
    init(data) {
      this.list = JSON.parse(JSON.stringify(data));
      this.list.forEach((item) => {
        item.statusText =
          Date.parse(new Date(item.activityEndTime)) -
            parseInt(new Date().getTime()) >
            0
            ? "进行中"
            : "已结束";
      });
    },
    cancellationApplication(id) {
      if (this.btnFlg) {
        this.btnFlg = false;
        this.cancellationRegistration(id);
      }
    },
    async cancellationRegistration(id) {
      const { data: res } = await request(
        {
          method: "post",
          url: "/pcp/activity/cancelRegistration",
          data: {
            memberId: getMemberId(),
            activityId: id,
          },
        },
        true
      );
      if (res.code !== 200) return this.$message.error("获取数据异常");
      this.$message({
        message: "已取消报名",
        type: "success",
      });
      this.$emit("change", this.currentPage);
      this.btnFlg = true;
    },
    timeIntercept(time) {
      if (!time) {
        return;
      }
      let data = "";
      time.split("-").forEach((item, index) => {
        if (index === 1) {
          data += item + "月";
        } else if (index === 2) {
          data += item.split(" ")[0] + "日";
        }
      });
      return data;
    },
    handleCurrentChange(val) {
      this.$emit('change', val);
    },
    goToDetail (clickId) {
      this.$router.push({
        name: 'activityDetails',
        query: {
          clickId
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
ul {
  li {
    height: 200px;
    width: 100%;
    margin-bottom: 23px;
    background-color: #f2f5fa;
    border-radius: 4px;
    position: relative;
    > img {
      width: 370px;
      height: 100%;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
    .mark {
      position: absolute;
      left: 310px;
      top: 0;
      width: 60px;
      height: 32px;
      line-height: 32px;
      border-bottom-left-radius: 10px;
      background-color: rgba(249, 63, 63, 100);
      color: rgba(255, 255, 255, 100);
      font-size: 16px;
      text-align: center;
      font-family: Microsoft Yahei;
    }

    > div:nth-of-type(1) {
      margin: 20px;
      flex: 1;
      > div:nth-of-type(1) {
        color: rgba(0, 0, 0, 0.9);
        font-size: 18px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
      }
      > div:nth-of-type(2) {
        margin-top: 20px;
        > i {
          font-size: 16px;
          margin-right: 6px;
        }
        > span {
          color: rgba(0, 0, 0, 0.6);
          font-size: 16px;
        }
      }
      > div:nth-of-type(3) {
        margin-top: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        > i {
          font-size: 16px;
          margin-right: 6px;
        }
        > span {
          color: rgba(0, 0, 0, 0.6);
          font-size: 16px;
        }
      }
      > div:nth-of-type(4) {
        margin-top: 10px;
        > i {
          font-size: 16px;
          margin-right: 6px;
        }
        > span {
          color: rgba(0, 0, 0, 0.6);
          font-size: 16px;
        }
      }
    }
    .application-btn {
      position: absolute;
      right: 20px;
      bottom: 22px;
      padding: 8px 20px;
      border-radius: 8px;
      // background-color: #ccc ;
      // color: #61779A ;
      font-size: 16px;
      font-family: Microsoft Yahei;
      background-color: rgba(97, 119, 154, 0.2);
      color: #555;
      cursor: pointer;
    }
  }
  li:hover {
    box-shadow: 0px 2px 10px 0px rgba(194, 194, 194, 100);
  }
}
</style>